import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  width: calc(100% - 2.5rem);
  margin: ${toRem(30)} auto 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Filter = styled.div`
  padding: 20px;

  .dropdown {
    width: 200px;
  }
`;
