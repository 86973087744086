import styled from 'styled-components';

import { black, white } from 'theme/colors';
import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const ProductWrapper = styled.div`
  position: relative;
  padding: 10px;
  width: calc(25% - 24px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 12px;

  .label {
    width: 100%;
    text-align: center;
  }
`;
