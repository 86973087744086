import React from 'react';

import ProductThumb from '../productThumb';
import Filters from '../filters';
import { Wrapper } from './ProductsGrid.styles';

const ProductsGrid = (props) => {
  //PROPS
  const { data, hasFilters = false, spacing = false } = props;
  //PROPS

  return (
    <>
      {hasFilters && <Filters />}
      <Wrapper spacing={spacing}>
        {data.map((product, index) => {
          return (
            <ProductThumb
              data={product.product}
              slug={product.slug}
              uri={product.seo.slug}
              key={index}
            />
          );
        })}
      </Wrapper>
    </>
  );
};

export default ProductsGrid;
