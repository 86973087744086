import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { Wrapper, Filter } from './Filters.styles';

const Filters = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  const options = [
    { value: 'rec', label: 'Mais Recente' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  const [option, selectOption] = useState({
    value: 'rec',
    label: 'Mais Recentes',
  });

  useEffect(() => {
    console.log(option);
  }, [option]);

  return (
    <Wrapper>
      <Filter>
        <Select
          value={option}
          onChange={selectOption}
          options={options}
          className="dropdown"
        />
      </Filter>
      <Filter>
        <Select
          value={option}
          onChange={selectOption}
          options={options}
          className="dropdown"
        />
      </Filter>
      <Filter>
        <Select
          value={option}
          onChange={selectOption}
          options={options}
          className="dropdown"
        />
      </Filter>
    </Wrapper>
  );
};

export default Filters;
