import React from 'react';

import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import FavComponent from '../favComp';

import { ProductWrapper } from './ProductThumb.styles';

const ProductThumb = (props) => {
  //PROPS
  const { data, slug, uri } = props;
  //PROPS

  return (
    <ProductWrapper>
      <FavComponent slug={slug} />
      <Link to={uri}>
        <GatsbyImage
          image={data.imagem?.localFile?.childImageSharp?.gatsbyImageData}
          alt={data.imagem?.altText}
        />
        <p className="label">{data.title}</p>
      </Link>
    </ProductWrapper>
  );
};

export default ProductThumb;
